import Honeybadger from "@honeybadger-io/js";

import {
  EMBEDDED_WIDGET_TRACKING_KEY,
  WIDGETS,
  ENV,
  HONEYBADGER_IGNORABLE_ERRORS,
  PARSE_STACK_REGEX,
  ENABLED_STATE_STORAGE_KEY,
  DEFAULT_MINIMUM_SUPPORTED_BROWSER_VERSIONS,
  WEBVIEW_REGEX_PATTERNS,
} from "./constants";

const convertToPlatformBrowserName = browserName =>
  browserName.replace(/\s/g, "");

const requiredMinBrowserVersion = (browserName, supportedBrowserVersionsMap) =>
  supportedBrowserVersionsMap[convertToPlatformBrowserName(browserName)];

const convertSemverToFloat = browserVersion => {
  const [majorVersion, minorVersion] = browserVersion.split(".");

  return parseFloat(`${majorVersion}.${minorVersion}`);
};

const isVersionSupported = (browserVersion, minBrowserVersionRequired) =>
  convertSemverToFloat(browserVersion) >= minBrowserVersionRequired;

export const initialiseWidgetTracking = () => {
  window[EMBEDDED_WIDGET_TRACKING_KEY] = {
    [WIDGETS.engage]: false,
    [WIDGETS.chat]: false,
    [WIDGETS.replay]: false,
  };
};

// Tracker function => Tracks whether the widget type can be embedded.
export const isWidgetEmbedded = widget => {
  const embeds = window[EMBEDDED_WIDGET_TRACKING_KEY];
  if (embeds[widget]) {
    /* eslint-disable-next-line */
    console.error(
      `${widget} widget is being embedded multiple times using NeetoWidget. This may lead to unexpected results.`
    );

    return true;
  }

  embeds[widget] = true;

  return false;
};

export const setupHoneybadger = () => {
  if (process.env.NODE_ENV !== ENV.production) return null;

  const widgetScript = Error().stack.match(PARSE_STACK_REGEX)[1];

  const honeybadger = Honeybadger.factory({
    apiKey: process.env.HONEYBADGER_API_KEY,
  });

  honeybadger
    .configure({
      environment: ENV.production,
      breadcrumbsEnabled: true,
      async: true,
      revision: process.env.HONEYBADGER_REVISION,
    })
    .beforeNotify(notice => {
      const isSameSource = notice.backtrace?.[0]?.file?.endsWith(widgetScript);
      if (!isSameSource) return false;

      return !HONEYBADGER_IGNORABLE_ERRORS.includes(notice?.message);
    });

  return honeybadger;
};

export const addToDocumentHead = (elementType, attributes) => {
  const element = document.createElement(elementType);
  for (const attr in attributes) {
    element[attr] = attributes[attr];
  }
  document.head.appendChild(element);
};

export const isArrayEmpty = array => {
  const isArray = array && Array.isArray(array);
  if (!isArray) return true;

  return array.length < 1;
};

export const getEnabledStateStorage = () => {
  try {
    // eslint-disable-next-line @bigbinary/neeto/no-local-storage
    return JSON.parse(localStorage?.getItem(ENABLED_STATE_STORAGE_KEY) || "{}");
  } catch {
    return {};
  }
};

export const setEnabledStateStorage = enabledState => {
  try {
    // eslint-disable-next-line @bigbinary/neeto/no-local-storage
    localStorage?.setItem(
      ENABLED_STATE_STORAGE_KEY,
      JSON.stringify(enabledState)
    );
  } catch {}
};

export const isBrowserCompatible = platformInfo => {
  const browserName = platformInfo.name;
  const browserVersion = platformInfo.version || "";

  const minBrowserVersionRequired = requiredMinBrowserVersion(
    browserName,
    DEFAULT_MINIMUM_SUPPORTED_BROWSER_VERSIONS
  );

  return isVersionSupported(browserVersion, minBrowserVersionRequired);
};

export const shouldPreloadWidget = (enabledStates, widget) =>
  Object.values(enabledStates).reduce(
    (acc, currentValue) => acc || currentValue[widget],
    false
  );

export const isNotWebView = userAgent => {
  const webviewRegex = new RegExp(
    `(${WEBVIEW_REGEX_PATTERNS.join("|")})`,
    "ig"
  );

  return !userAgent.match(webviewRegex);
};

export const isWidgetEnabled = (token, booleanFlag) =>
  token.client_application_enabled ? booleanFlag ?? token.enabled : false;
